$(document).foundation();

$(window).load(function(){
    $('.se-pre-con').fadeOut("slow");
});

$(document).ready(function(){
    $(".panel, .md").hide(0).delay(500).fadeIn(500);
    $.ajax({
       type: "GET",
        url: "slides",
        success: function(data){
            $.backstretch(data, {duration: 5000, fade: 1000});
        }
    });
});

$('.close a').on('click',function(){
    $(this).closest('.pnl').fadeOut("slow");
});

$('.side-nav li:has("ul")').children('ul').hide(); //hide submenu
$('.side-nav li:has("ul")').addClass('hasChildren'); // add class to li ul child
$('.side-nav li:has("ul")').click(function(){
    $(this).toggleClass( "active" ) // add active class to clicked menu item
    $(this).children('ul').slideToggle(); //toggle submenu
});